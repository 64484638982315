import { Button } from "antd";
import moment from "moment";
import React, { forwardRef, Fragment, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../api/getSettings";
import number2words from "../../utils/numberToWords";
import "./style.css";

const PrintToPdf = forwardRef(({ data, invoiceData }, ref) => {
	return (
		<Fragment>
			<div ref={ref} className="wrapper">
				<div className="box4">
					<img
						src="/henat.png"
						width={150}
						className="ms-4 mb-1 "
						alt="Logo"
					/>
					<h4>{invoiceData?.company_name}</h4>
					<p>{invoiceData?.address}</p>
					<p>
						{invoiceData?.email} . {invoiceData?.phone}
					</p>
				</div>

				<div className="box5">
					<table className="table2">
						{/* <tr>
							<th>Client ID</th>
							<td>{data?.customer_id}</td>
						</tr> */}
						<tr>
							<th>Client Name</th>
							<td>{data?.customer.name}</td>
						</tr>
						<tr>
							<th>Address</th>
							<td>{data?.customer.address}</td>
						</tr>
						{/* <tr>
							<th>Contact No</th>
							<td>{data?.customer.phone}</td>
						</tr> */}
					</table>
				</div>

				<div className="box6">
					<table className="table2">
						<tr>
							<th colSpan={2}>
								<h3 className="center">PACKING SLIP</h3>
							</th>
						</tr>
						<tr>
							<th>Invoice No</th>
							<td>{data?.id}</td>
						</tr>
						<tr>
							<th>Invoice Date</th>
							<td>{moment(data?.date).format("YYYY-MM-DD")}</td>
						</tr>
					</table>
				</div>

				<div className="box7">
					<table className="table1">
						<thead>
						<th>Sl</th>
						<th>Variety</th>
						<th>Length</th>
						<th>Pack Rate</th>
						<th>Box Number.</th>
						<th>Stem Count</th>

						</thead>
						<tbody>
						{data &&
							data.saleInvoiceProduct.map((d) => (
								<tr key={d.id}>
									<td>{d.id}</td>
									<td>
										<p>{d.product.name}</p>
									</td>
									<td>
										<p>{d.length}</p>
									</td>
									<td>{d.pack_rate}</td>
									<td>{d.boxes}</td>
									<td>{d.product_quantity}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="box9">
					<table className="table2">
						<tr>
							<th>Total Boxes</th>
							<td>
								{data &&
									data.saleInvoiceProduct.reduce(
										(p, c) => p + c.boxes,
										0
									)}
							</td>
						</tr>
						<tr>
							<th>Total Stems</th>
							<td>
								{data &&
									data.saleInvoiceProduct.reduce(
										(p, c) => p + c.product_quantity,
										0
									)}
							</td>
						</tr>
					</table>
				</div>


				<div className="box10">
					<hr/>
					<p>Received By</p>
				</div>

				<div className="box11">
					<hr/>
					<p>Authorized By</p>
				</div>

				<div className="box12">
					<hr/>
					{invoiceData?.footer}
				</div>
			</div>
		</Fragment>
	);
});

const PackingSlip = ({data}) => {
	console.log(data);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const [invoiceData, setInvoiceData] = useState(null);
	useEffect(() => {
		getSetting().then((data) => setInvoiceData(data.result));
	}, []);

	return (
		<div>
			<div className="hidden">
				<PrintToPdf
					ref={componentRef}
					data={data}
					invoiceData={invoiceData}
				/>
			</div>
			{invoiceData && (
				<Button type="primary" shape="round" onClick={handlePrint}>
					Packing Slip Print
				</Button>
			)}
		</div>
	);
};

export default PackingSlip;
